export type LocaleFormat =
  | 'arb'
  | 'csv'
  | 'html'
  | 'ini'
  | 'json'
  | 'mo'
  | 'php'
  | 'po'
  | 'pot'
  | 'plist'
  | 'bplist'
  | 'properties'
  | 'res'
  | 'resx'
  | 'sql'
  | 'strings'
  | 'stringsdict'
  | 'tmx'
  | 'ts'
  | 'txt'
  | 'xlf'
  | 'xliff'
  | 'xml'
  | 'yml'

export type LocalizationClient = {
  fetchWorkplaceGivingTranslationsArchive: (localeFormat?: LocaleFormat) => Promise<ArrayBuffer>
}

export const languageCodesMapping = {
  af: 'af-ZA',
  'af-ZA': 'af-ZA',
  sq: 'sq-AL',
  'sq-AL': 'sq-AL',
  am: 'am-ET',
  'am-ET': 'am-ET',
  ar: 'ar',
  'ar-001': 'ar',
  'ar-DZ': 'ar',
  'ar-EG': 'ar',
  'ar-SA': 'ar',
  'ar-AE': 'ar',
  hy: 'hy-AM',
  'hy-AM': 'hy-AM',
  as: 'as-IN',
  'as-IN': 'as-IN',
  az: 'az-AZ',
  'az-AZ': 'az-AZ',
  eu: 'eu-ES',
  'eu-ES': 'eu-ES',
  be: 'be-BY',
  'be-BY': 'be-BY',
  bn: 'bn-IN',
  'bn-IN': 'bn-IN',
  'bn-BD': 'bn-IN',
  bs: 'bs-BA',
  'bs-BA': 'bs-BA',
  bg: 'bg-BG',
  'bg-BG': 'bg-BG',
  my: 'my-MM',
  'my-MM': 'my-MM',
  yue: 'zh-Hant',
  'yue-HK': 'zh-Hant',
  'yue-Hant': 'zh-Hant',
  'yue-Hant-HK': 'zh-Hant',
  ca: 'ca-ES',
  'ca-ES': 'ca-ES',
  zh: 'zh-Hans',
  'zh-CN': 'zh-Hans',
  'zh-Hans': 'zh-Hans',
  'zh-Hans-CN': 'zh-Hans',
  'zh-HK': 'zh-Hant',
  'zh-Hant-HK': 'zh-Hant',
  'zh-TW': 'zh-Hant',
  'zh-Hant': 'zh-Hant',
  'zh-Hant-TW': 'zh-Hant',
  hr: 'hr-HR',
  'hr-HR': 'hr-HR',
  cs: 'cs-CZ',
  'cs-CZ': 'cs-CZ',
  da: 'da-DK',
  'da-DK': 'da-DK',
  nl: 'nl-NL',
  'nl-NL': 'nl-NL',
  'nl-BE': 'nl-NL',
  en: 'en-US',
  'en-US': 'en-US',
  'en-AU': 'en-US',
  'en-CA': 'en-US',
  'en-IN': 'en-US',
  'en-IE': 'en-US',
  'en-PH': 'en-US',
  'en-GB': 'en-US',
  'en-SG': 'en-US',
  'en-NZ': 'en-US',
  et: 'et-EE',
  'et-EE': 'et-EE',
  fil: 'fil-PH',
  'fil-PH': 'fil-PH',
  fi: 'fi-FI',
  'fi-FI': 'fi-FI',
  fr: 'fr-FR',
  'fr-FR': 'fr-FR',
  'fr-CA': 'fr-CA',
  'fr-BE': 'fr-FR',
  'ff-SN': 'ff-SN',
  gl: 'gl-ES',
  'gl-ES': 'gl-ES',
  ka: 'ka-GE',
  'ka-GE': 'ka-GE',
  de: 'de-DE',
  'de-DE': 'de-DE',
  'de-CH': 'de-CH',
  'de-AT': 'de-DE',
  'de-LI': 'de-DE',
  el: 'el-GR',
  'el-GR': 'el-GR',
  gu: 'gu-IN',
  'gu-IN': 'gu-IN',
  he: 'he-IL',
  'he-IL': 'he-IL',
  iw: 'he-IL',
  'iw-IL': 'he-IL',
  'ha-NG': 'ha-NG',
  hi: 'hi-IN',
  'hi-IN': 'hi-IN',
  hu: 'hu-HU',
  'hu-HU': 'hu-HU',
  is: 'is-IS',
  'is-IS': 'is-IS',
  id: 'id-ID',
  'id-ID': 'id-ID',
  in: 'id-ID',
  'in-ID': 'id-ID',
  ga: 'ga-IE',
  'ga-IE': 'ga-IE',
  it: 'it-IT',
  'it-IT': 'it-IT',
  'ig-NG': 'ig-NG',
  ja: 'ja-JP',
  'ja-JP': 'ja-JP',
  jv: 'jv-ID',
  'jv-ID': 'jv-ID',
  kn: 'kn-IN',
  'kn-IN': 'kn-IN',
  kk: 'kk-KZ',
  'kk-KZ': 'kk-KZ',
  km: 'km-KH',
  'km-KH': 'km-KH',
  ko: 'ko-KR',
  'ko-KR': 'ko-KR',
  'ku-TR': 'ku-TR',
  ky: 'ky-KG',
  'ky-KG': 'ky-KG',
  lo: 'lo-LA',
  'lo-LA': 'lo-LA',
  lv: 'lv-LV',
  'lv-LV': 'lv-LV',
  lt: 'lt-LT',
  'lt-LT': 'lt-LT',
  'ma-IN': 'ma-IN',
  'mg-MG': 'mg-MG',
  mk: 'mk-MK',
  'mk-MK': 'mk-MK',
  ms: 'ms-MY',
  'ms-MY': 'ms-MY',
  ml: 'ml-IN',
  'ml-IN': 'ml-IN',
  mr: 'mr-IN',
  'mr-IN': 'mr-IN',
  mn: 'mn-MN',
  'mn-MN': 'mn-MN',
  ne: 'ne-NP',
  'ne-NP': 'ne-NP',
  no: 'no-NO',
  'no-NO': 'no-NO',
  nb: 'no-NO',
  'nb-NO': 'no-NO',
  'om-ET': 'om-ET',
  or: 'or-IN',
  'or-IN': 'or-IN',
  ps: 'ps-AF',
  'ps-AF': 'ps-AF',
  fa: 'fa-IR',
  'fa-IR': 'fa-IR',
  pl: 'pl-PL',
  'pl-PL': 'pl-PL',
  pt: 'pt-PT',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-PT',
  pa: 'pa-IN',
  'pa-IN': 'pa-IN',
  ro: 'ro-RO',
  'ro-RO': 'ro-RO',
  mo: 'ro-RO',
  'pa-PK': 'pa-PK',
  ru: 'ru-RU',
  'ru-RU': 'ru-RU',
  sr: 'sr-RS',
  'sr-RS': 'sr-RS',
  'sr-Cyrl-RS': 'sr-RS',
  'sr-Latn': 'sr-RS',
  sd: 'sd-IN',
  'sd-IN': 'sd-IN',
  'sn-ZW': 'sn-ZW',
  si: 'si-LK',
  'si-LK': 'si-LK',
  sk: 'sk-SK',
  'sk-SK': 'sk-SK',
  sl: 'sl-SI',
  'sl-SI': 'sl-SI',
  so: 'so-SO',
  'so-SO': 'so-SO',
  es: 'es-ES',
  'es-ES': 'es-ES',
  'es-419': 'es-419',
  'es-MX': 'es-419',
  'es-US': 'es-419',
  'es-AR': 'es-419',
  'es-CO': 'es-419',
  sw: 'sw',
  'sw-TZ': 'sw',
  'sw-KE': 'sw',
  'su-ID': 'su-ID',
  sv: 'sv-SE',
  'sv-SE': 'sv-SE',
  ta: 'ta-IN',
  'ta-IN': 'ta-IN',
  tl: 'tl-PH',
  'tl-PH': 'tl-PH',
  te: 'te-IN',
  'te-IN': 'te-IN',
  th: 'th-TH',
  'th-TH': 'th-TH',
  tr: 'tr-TR',
  'tr-TR': 'tr-TR',
  tk: 'tk-TM',
  'tk-TM': 'tk-TM',
  uk: 'uk-UA',
  'uk-UA': 'uk-UA',
  ur: 'ur-IN',
  'ur-IN': 'ur-IN',
  'ur-PK': 'ur-IN',
  uz: 'uz-UZ',
  'uz-UZ': 'uz-UZ',
  vi: 'vi-VN',
  'vi-VN': 'vi-VN',
  cy: 'cy',
  'cy-GB': 'cy',
  zu: 'zu-ZA',
  'zu-ZA': 'zu-ZA',
  'wo-SN': 'wo-SN',
  'xh-ZA': 'xh-ZA',
  'yo-NG': 'yo-NG',
  'ceb-PH': 'ceb-PH'
}

export type LanguageCode = keyof typeof languageCodesMapping
