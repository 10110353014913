import { Skeleton, Text, Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import pick from 'lodash/pick'

import { DashboardCard } from '../DashboardCard/DashboardCard'

import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import { formatMinutesToHours } from '@percent/workplace-giving/utils/dayjs/dayjs'
import classes from './TotalVolunteeredCard.module.css'
import { useTotalVolunteeredCard } from './useTotalVolunteeredCard'

export function TotalVolunteeredCard(): JSX.Element {
  const { t } = useTranslation()
  const { cssVariables } = useColorTheme()
  const { isLoading, totalVolunteeredMinutes } = useTotalVolunteeredCard()

  const formattedTime =
    !isLoading && totalVolunteeredMinutes > 0 ? formatMinutesToHours(totalVolunteeredMinutes, t) : '0h'

  return (
    <DashboardCard icon="stopwatch-outline" testId="total-volunteered-card" gradient>
      <Box className={classes.ContentWrapper}>
        <Text className={classes.Text} style={pick(cssVariables, '--black')}>
          {t('workplace_giving.volunteering.totalVolunteered.title')}
        </Text>
        {isLoading && (
          <Skeleton data-testid="total-volunteered-card-skeleton" height="2rem" radius="xl" width="120px" />
        )}
        {!isLoading && (
          <Text className={classes.TextLarge} style={pick(cssVariables, '--black')}>
            {formattedTime}
          </Text>
        )}
      </Box>
    </DashboardCard>
  )
}
