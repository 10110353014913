import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'
import { Box } from '@mantine/core'

import { DashboardCard } from '../DashboardCard/DashboardCard'

import classes from './Upcoming.module.scss'
import { useAuth } from '@percent/workplace-giving/common/hooks/useAuth/useAuth'
import { getAccountFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import upcomingBanner from '@percent/workplace-giving/assets/images/upcoming-banner.png'
import { ButtonText, Text } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { getAccountOpportunities } from '@percent/workplace-giving/api/opportunity/getAccountOpportunities/getAccountOpportunities'
import { useQuery } from '@percent/workplace-giving/common/hooks'
import { SkeletonLoader } from './SkeletonLoader/SkeletonLoader'
import { UpcomingItem } from './UpcomingItem/UpcomingItem'

export function Upcoming() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const authState = useAuth()
  const account = getAccountFromAuthState(authState.state)!
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isFirstLogin = useMemo(() => !account?.preferredName, [])

  const endDate = useMemo(() => new Date().toISOString(), [])

  const { data, isLoading } = useQuery(
    [
      'getAccountOpportunitiesCount',
      {
        pageSize: 3,
        participationStatus: ['registered', 'confirmed'],
        endDateGt: endDate,
        direction: 'ASC'
      }
    ],
    getAccountOpportunities,
    { staleTime: Infinity }
  )

  const noUpcoming = data?.totalResults ? data?.totalResults <= 0 : true

  if (isLoading) {
    return <SkeletonLoader />
  }

  return (
    <DashboardCard
      testId="upcomingWrapper"
      title={!isFirstLogin && !noUpcoming ? t('workplace_giving.upcoming.title') : undefined}
      seeAllHref={!isFirstLogin && !noUpcoming ? AppRoute.MY_OPPORTUNITY : undefined}
    >
      <Box className={classes.Wrapper}>
        {isFirstLogin || noUpcoming ? <img className={classes.Banner} src={upcomingBanner} alt="" /> : null}

        {isFirstLogin ? (
          <>
            <Text className={classes.Title}>{t('workplace_giving.upcoming.startVolunteering.title')}</Text>
            <Text className={classes.Description}>{t('workplace_giving.upcoming.startVolunteering.description')}</Text>
          </>
        ) : null}

        {!isFirstLogin && noUpcoming ? (
          <>
            <Text className={classes.Title}>{t('workplace_giving.upcoming.keepVolunteering.title')}</Text>
            <Text className={classes.Description}>{t('workplace_giving.upcoming.keepVolunteering.description')}</Text>
          </>
        ) : null}

        {data?.data ? (
          <Box>
            {data.data.map(item => (
              <UpcomingItem {...item} />
            ))}
          </Box>
        ) : undefined}

        {isFirstLogin || noUpcoming ? (
          <Box className={classes.LinkWrapper}>
            <ButtonText
              onPress={() => {
                navigate(AppRoute.VOLUNTEER)
              }}
            >
              {t('workplace_giving.upcoming.exploreBtn')}
            </ButtonText>
          </Box>
        ) : null}
      </Box>
    </DashboardCard>
  )
}
