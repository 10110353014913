import { theme as lemonadeTheme } from '@percent/lemonade'

export const CardContentWrapper = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: lemonadeTheme.sizes[2],
    marginBottom: lemonadeTheme.sizes[2]
  },

  '& p': {
    color: lemonadeTheme.colors.gray800
  }
}

export const Styles = {
  CardContentWrapper,
  ContentFooter: {
    '& p': {
      fontSize: lemonadeTheme.fontSizes.bodySmall,
      fontWeight: lemonadeTheme.fontWeights.medium,
      lineHeight: lemonadeTheme.sizes[6]
    }
  },
  FooterOrgInfo: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'top',
    gap: lemonadeTheme.sizes[8]
  }
}
