import { supportedLocoLocales, supportedAppLocales } from '../supportedLocales'
import { languageCodesMapping } from '../translations.types'

const DEFAULT_LANGUAGE = 'en-US'

const findMapping = (langCode: string) => {
  const mappingEntry = Object.entries(languageCodesMapping).find(entry => entry[0] === langCode)

  return mappingEntry?.[1] || undefined
}

const checkAvailability = (locale: string): { supported: boolean; code: string } => {
  const isSupported = supportedLocoLocales.includes(locale) && supportedAppLocales.includes(locale)
  const mappedLocale = findMapping(locale)

  if (!isSupported && mappedLocale) {
    return {
      supported: supportedLocoLocales.includes(mappedLocale) && supportedAppLocales.includes(mappedLocale),
      code: mappedLocale
    }
  }

  return {
    supported: isSupported,
    code: mappedLocale || locale
  }
}

export const getUserLanguage = () => {
  const navigatorLang = navigator.language

  if (navigatorLang) {
    const available = checkAvailability(navigatorLang)

    if (available.supported) {
      return available.code
    }
  }

  return DEFAULT_LANGUAGE
}
