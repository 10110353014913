import throttle from 'lodash/throttle'
import { useLayoutEffect, useRef, useState } from 'react'

export const useScrolled = (): boolean => {
  const [scrolled, setScrolled] = useState(false)

  const scrolledRef = useRef(scrolled)
  const setScrolledValue = (data: boolean) => {
    scrolledRef.current = data
    setScrolled(data)
  }

  const handleScroll = throttle(() => {
    const scrolledFromTop = window.scrollY

    if (scrolledRef.current && scrolledFromTop > 0) {
      setScrolledValue(true)
    } else if (!scrolledRef.current && scrolledFromTop > 0) {
      setScrolledValue(true)
    } else {
      setScrolledValue(false)
    }
  }, 20)

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return scrolled
}
