import { useEffect } from 'react'

import { getAccountBudget } from '@percent/workplace-giving/api/opportunity/getAccountBudget/getAccountBudget'
import { useLogger, useQuery } from '@percent/workplace-giving/common/hooks'

export type TotalVolunteeredCardReturn = Readonly<{
  isLoading: boolean
  totalVolunteeredMinutes: number
}>

export const useTotalVolunteeredCard = (): TotalVolunteeredCardReturn => {
  const { logError } = useLogger()
  const { data, isLoading, error } = useQuery(['getAccountBudget'], getAccountBudget, {
    staleTime: 1000 * 20
  })

  useEffect(() => {
    if (error) {
      logError(error)
    }
  }, [error, logError])

  return {
    isLoading,
    totalVolunteeredMinutes: data?.totalMinutes || 0
  }
}
