import { Box, Skeleton } from '@mantine/core'

import { DashboardCard } from '../../DashboardCard/DashboardCard'

export function SkeletonLoader() {
  return (
    <Box style={{ width: '100%' }} data-testid="upcomingSkeletonLoader">
      <DashboardCard>
        <Box style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
          <Skeleton height={24} display="inline-block" width="45%" />
          <Skeleton height={24} display="inline-block" width="12%" />
        </Box>
        {Array.from({ length: 3 }).map(item => (
          <Box key={`skeleton-item-${item}`} style={{ display: 'flex', flexFlow: 'row', gap: '0.25rem' }}>
            <Skeleton height={88} display="inline-block" width="20%" />
            <Skeleton height={88} display="inline-block" width="80%" />
          </Box>
        ))}
      </DashboardCard>
    </Box>
  )
}
