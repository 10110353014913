import { getConfig } from '../config/query'

export type GamificationDisplayData = {
  title?: string
  body?: string
}

export const getGamificationDisplayConfig = getConfig<'gamification_display', GamificationDisplayData>(
  'gamification_display'
)
