import type { ColorPaletteType } from '../context/colorTheme/colorThemeContextController/ColorThemeContextController'

import { hexToRgbTriplet } from './hexToRgbTriplet'

export type CssVariablesColorPalette = {
  [key in keyof ColorPaletteType as `--${key}`]: ColorPaletteType[key]
}

export const colorPaletteToCssVariables = (colorPalette: ColorPaletteType): CssVariablesColorPalette =>
  Object.entries(colorPalette).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[`--${key}`] = hexToRgbTriplet(value)

    return acc
  }, {}) as CssVariablesColorPalette
