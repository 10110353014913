import { useTranslation } from 'react-i18next'

import { SuggestedRow } from '../SuggestedRow/SuggestedRow'

import { OpportunityCardSkeleton } from '@percent/workplace-giving/common/components/OpportunityCard/OpportunityCardSkeleton'
import { OpportunityCard } from '@percent/workplace-giving/common/components/OpportunityCard/OpportunityCard'
import classes from './SuggestedOpportunitiesRow.module.css'
import { useSuggestedOpportunitiesRow } from './useSuggestedOpportunitiesRow'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'

export function SuggestedOpportunitiesRow() {
  const { t } = useTranslation()
  const { opportunities, isLoading, skeletonData } = useSuggestedOpportunitiesRow()
  const { track } = useAnalytics()

  const sectionTitle = t('workplace_giving.volunteering.suggested.title')

  if (isLoading) {
    return (
      <SuggestedRow
        title={sectionTitle}
        testId="suggested-opportunities-row-skeleton"
        items={skeletonData}
        keyExtractor={key => key}
        renderItem={() => <OpportunityCardSkeleton />}
      />
    )
  }

  if (opportunities.length === 0) {
    return null
  }

  return (
    <SuggestedRow
      title={sectionTitle}
      testId="suggested-opportunities-row"
      seeAllHref="/volunteering"
      items={opportunities}
      keyExtractor={o => o.id}
      renderItem={o => (
        <OpportunityCard
          className={classes.OpportunityCard}
          opportunity={o}
          track={() => {
            track({
              event: 'Volunteering Opportunity Display Suggested',
              properties: {
                origin: 'New Home Suggested Opportunities',
                opportunityType: o.type,
                opportunityLocationType: o.location.type
              }
            })
          }}
        />
      )}
    />
  )
}
