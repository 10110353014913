/* eslint-disable global-require */
export const translationResources = {
  'en-US': {
    workplaceGiving: require('@percent/workplace-giving/i18n/data/en/wpg.json')
  },
  'en-GB': {
    workplaceGiving: require('@percent/workplace-giving/i18n/data/en/wpg.json')
  },
  'pl-PL': {
    workplaceGiving: require('@percent/workplace-giving/i18n/data/pl-PL/wpg.json')
  },
  'pt-BR': {
    workplaceGiving: require('@percent/workplace-giving/i18n/data/pt-BR/wpg.json')
  },
  'nl-NL': {
    workplaceGiving: require('@percent/workplace-giving/i18n/data/nl-NL/wpg.json')
  },
  'it-IT': {
    workplaceGiving: require('@percent/workplace-giving/i18n/data/it-IT/wpg.json')
  },
  'fr-FR': {
    workplaceGiving: require('@percent/workplace-giving/i18n/data/fr-FR/wpg.json')
  },
  'es-ES': {
    workplaceGiving: require('@percent/workplace-giving/i18n/data/es-ES/wpg.json')
  },
  'es-419': {
    workplaceGiving: require('@percent/workplace-giving/i18n/data/es-419/wpg.json')
  },
  'de-DE': {
    workplaceGiving: require('@percent/workplace-giving/i18n/data/de-DE/wpg.json')
  }
}
