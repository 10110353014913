import { Box } from '@mantine/core'
import type { PropsWithChildren } from 'react'
import { clsx } from '@mantine/styles'
import type { To } from 'react-router-dom-v6'

import { SeeAllBtn } from '../SeeAllBtn/SeeAllBtn'

import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import { Text, Icon } from '@percent/lemonade'
import type { IconProps } from 'libs/shared/ui-lemonade/src/components/icon'
import classes from './DashboardCard.module.css'

export type DashboardCardProps = Readonly<{
  icon?: IconProps['name']
  title?: string
  seeAllHref?: To
  gradient?: boolean
  testId?: string
}>

function DashboardIcon({ icon }: { icon: IconProps['name'] }): JSX.Element {
  const { pick } = useColorTheme()

  return (
    <Box className={classes.TitleIconWrapper} style={pick('--primary100', '--primary400')}>
      <Icon name={icon} size={5} color="inherit" />
    </Box>
  )
}

function DashboardTitle({ title }: { title: string }): JSX.Element {
  const { cssVariables } = useColorTheme()

  return (
    <Text size="large" fontWeight="semiBold" color={cssVariables['--black']}>
      {title}
    </Text>
  )
}

function DashboardTitleRow({
  icon,
  title,
  seeAllHref
}: Pick<DashboardCardProps, 'icon' | 'title' | 'seeAllHref'>): JSX.Element {
  return (
    <Box className={classes.TitleRow}>
      <Box className={classes.TitleWrapper}>
        {icon && <DashboardIcon icon={icon} />}
        {title && <DashboardTitle title={title} />}
      </Box>
      {seeAllHref && (
        <Box className={classes.SeeMoreBtnWrapper}>
          <SeeAllBtn href={seeAllHref} />
        </Box>
      )}
    </Box>
  )
}

export function DashboardCard({
  icon,
  title,
  seeAllHref,
  gradient = false,
  children,
  testId
}: PropsWithChildren<DashboardCardProps>): JSX.Element {
  const { pick } = useColorTheme()

  const showTitleRow = !!icon || !!title || !!seeAllHref

  return (
    <Box
      className={clsx(classes.DashboardCard, { [classes.GradientBackground]: gradient })}
      style={pick('--black', '--primary400')}
      data-testid={testId}
    >
      <Box>
        {showTitleRow && <DashboardTitleRow icon={icon} title={title} seeAllHref={seeAllHref} />}
        {children}
      </Box>
    </Box>
  )
}
