import { useTranslation } from 'react-i18next'
import { Box, Skeleton } from '@mantine/core'
import { sanitize } from 'isomorphic-dompurify'

import { DashboardCard } from '../DashboardCard/DashboardCard'

import { useQuery } from '@percent/workplace-giving/common/hooks'
import { getGamificationDisplayConfig } from '@percent/workplace-giving/api/gamification/getGamificationDisplayConfig'
import { GamificationBadges } from '@percent/workplace-giving/common/components/GamificationBadges/GamificationBadges'
import classes from './FoundationRewards.module.scss'

export function FoundationRewards() {
  const { t } = useTranslation()

  const { data, isLoading } = useQuery(['getGamificationDisplayConfig'], getGamificationDisplayConfig, {
    staleTime: 1000 * 60
  })

  if (isLoading) {
    return <Skeleton data-testid="gamification-section-loader" variant="rectangular" width="100%" height={100} />
  }

  return (
    <DashboardCard title={data?.title ?? t('workplace_giving.sidePanel.achievements')} testId="gamification-section">
      <Box>
        <GamificationBadges />
        {data?.body && (
          <Box
            className={classes.Footer}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sanitize(data.body, { ALLOWED_TAGS: ['p', 'a'] })
            }}
          />
        )}
      </Box>
    </DashboardCard>
  )
}
