import type { ListResponse } from '../../goodstack'

import type { InfiniteQueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import type { SearchOpportunitiesQuery, SearchOpportunitiesResponseRow } from './searchOpportunities.types'

export const searchOpportunities: InfiniteQueryFn<
  [string, SearchOpportunitiesQuery],
  string,
  ListResponse<SearchOpportunitiesResponseRow>
> = async (
  client,
  [
    _,
    {
      type,
      locationType,
      relevantSkills,
      country,
      city,
      query,
      direction = 'ASC',
      pageSize = 25,
      timeOfDay,
      activities
    }
  ],
  cursor
) =>
  (
    await (cursor
      ? client.get<ListResponse<SearchOpportunitiesResponseRow>>(cursor, {
          withCredentials: true
        })
      : client.get<ListResponse<SearchOpportunitiesResponseRow>>(`/opportunities-search`, {
          params: {
            pageSize,
            direction,
            query,
            relevantSkills,
            country,
            city,
            locationType,
            type,
            timeOfDay,
            activities
          },
          withCredentials: true
        }))
  ).data
