import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Icon, Text } from '@percent/lemonade'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'
import { formatShortOfflineOpportunityAddress } from '@percent/workplace-giving/utils/formatOfflineOpportunityAddress'
import { SearchOpportunitiesResponseRow } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { OpportunityLocationTypeDto, OpportunityType } from '@percent/workplace-giving/api/opportunity/dto'
import { Styles } from './OpportunityCard.styles'

export function OpportunityCardContent({
  startDate,
  location,
  participants,
  timeTracking,
  type
}: SearchOpportunitiesResponseRow) {
  const { t } = useTranslation()
  const { formatTime, formatDate } = useDateTimeFmt()

  return (
    <Box sx={Styles.CardContentWrapper}>
      {timeTracking === 'manual' || !startDate ? null : (
        <>
          <Box data-testid="opportunity-card-date-info">
            <Icon name="calendar" size="m" color="neutral300" />
            <Text as="p" size="small" fontWeight="medium">
              {formatDate(new Date(startDate))}
            </Text>
          </Box>
          <Box>
            <Icon name="clock" size="m" color="neutral300" />
            <Text as="p" size="small" fontWeight="medium">
              {formatTime(new Date(startDate))}
            </Text>
          </Box>
        </>
      )}
      {type === OpportunityType.EventSeries ? (
        <Box>
          <Icon name="info" size="m" color="neutral300" />
          <Text as="p" size="small" fontWeight="medium">
            {t('workplace_giving.volunteering.multipleSessions')}
          </Text>
        </Box>
      ) : (
        <>
          <Box>
            <Icon
              name={location.type === OpportunityLocationTypeDto.Virtual ? 'virtual-meeting' : 'pin'}
              size="m"
              color="neutral300"
            />
            <Text as="p" size="small" fontWeight="medium">
              {location.type === OpportunityLocationTypeDto.Virtual
                ? t('workplace_giving.volunteering.virtualOpportunity')
                : formatShortOfflineOpportunityAddress({ location })}
            </Text>
          </Box>
          <Box>
            <Icon name="agents" size="m" color="neutral300" />
            <Text as="p" size="small" fontWeight="medium">
              {t('workplace_giving.volunteering.attendees')} {participants.count}
              {participants.spots ? `/${participants.spots}` : null}
            </Text>
          </Box>
        </>
      )}
    </Box>
  )
}
