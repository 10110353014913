import { Box } from '@mantine/core'
import { clsx } from '@mantine/styles'

import { WelcomeHeader } from './WelcomeHeader/WelcomeHeader'
import classes from './NewHome.module.css'
import { Upcoming } from './Upcoming/Upcoming'
import { TotalVolunteeredCard } from './TotalVolunteeredCard/TotalVolunteeredCard'
import { SuggestedOpportunitiesRow } from './SuggestedOpportunitiesRow/SuggestedOpportunitiesRow'
import { useScrolled } from './useScrolled'
import { TotalDonatedCard } from './TotalDonatedCard/TotalDonatedCard'
import { FoundationRewards } from './FoundationRewards/FoundationRewards'
import { usePermission } from '@percent/workplace-giving/common/hooks/usePermission/usePermission'
import { feature, scope } from '@percent/workplace-giving/common/hoc/withPermissions'

export function NewHome() {
  const scrolled = useScrolled()

  const has = usePermission()
  const volunteeringEnabled = has(scope('volunteering'))
  const gamificationEnabled = has(scope('gamification'), feature('gamification'))

  return (
    <Box className={classes.PageContainer}>
      <WelcomeHeader scrolled={scrolled} />
      <Box className={classes.ContentWrapper} data-testid="new-home-page-wrapper">
        <Box>
          <Upcoming />
        </Box>
        <Box className={classes.CardsWrapper}>
          <Box
            className={clsx(classes.TotalsWrapper, { [classes.TotalsWrapperWithGamification]: gamificationEnabled })}
          >
            {/* TODO: if volunteering disabled show other donation related content to match the layout */}
            {volunteeringEnabled ? <TotalVolunteeredCard /> : <Box />}
            <TotalDonatedCard />
          </Box>
          {gamificationEnabled ? (
            <Box className={classes.GamificationWrapper}>
              <FoundationRewards />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box className={classes.PageContent}>
        <SuggestedOpportunitiesRow />
      </Box>
    </Box>
  )
}
