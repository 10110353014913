import { Skeleton, Text, Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import pick from 'lodash/pick'

import { DashboardCard } from '../DashboardCard/DashboardCard'

import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import { useMoneyFormat } from '@percent/workplace-giving/common/hooks'
import classes from './TotalDonatedCard.module.css'
import { useTotalDonatedCard } from './useTotalDonatedCard'

export function TotalDonatedCard(): JSX.Element {
  const { t } = useTranslation()
  const { cssVariables } = useColorTheme()
  const { isLoading, totalDonated } = useTotalDonatedCard()
  const { moneyFormat } = useMoneyFormat()

  return (
    <DashboardCard icon="donate-outline" testId="total-donated-card" gradient>
      <Box className={classes.ContentWrapper}>
        <Text className={classes.Text} style={pick(cssVariables, '--black')}>
          {t('workplace_giving.totalDonated.title')}
        </Text>
        {isLoading && <Skeleton data-testid="total-donated-card-skeleton" height="2rem" radius="xl" width="120px" />}
        {!isLoading && (
          <Text className={classes.TextLarge} style={pick(cssVariables, '--black')}>
            {moneyFormat(totalDonated)}
          </Text>
        )}
      </Box>
    </DashboardCard>
  )
}
