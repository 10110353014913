export const AppRoute = {
  NEW_HOME: '/new-home',
  HOME: '/home',
  MY_IMPACT: '/my-impact',
  MY_OPPORTUNITY: '/my-opportunity',
  MY_FUNDRAISER: '/my-fundraiser',
  SIGN_IN: '/sign-in',
  OTP_VERIFY: '/sign-in/2fa',
  SSO_SIGN_IN: '/sign-in/sso',
  SSO_GATEWAY: '/signin/sso/gateway',
  FORGOT_PASSWORD: '/forgot-password',
  VOLUNTEER: '/volunteer',
  OPPORTUNITY_DETAILS: '/volunteer/opportunities/:opportunityId',
  FUNDRAISER: '/fundraisers/:fundraiserSlug',
  CREATE_OPPORTUNITY: '/volunteer/opportunities',
  EDIT_OPPORTUNITY: '/volunteer/opportunities/:opportunityId/edit',
  FUNDRAISER_WIZARD: '/fundraise/fundraiser-wizard',
  EDIT_FUNDRAISER: '/fundraise/fundraiser-wizard/:fundraiserId/edit',
  LOG_VOLUNTEERING_ACTIVITY: '/volunteer/log-activity'
}
