import type { RawValidResponse } from '../../goodstack'

import type { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import type { OpportunitiesBudget } from '@percent/workplace-giving/api/opportunity/getAccountBudget/getAccountBudget.types'

export const getAccountBudget: QueryFn<[string], OpportunitiesBudget> = async client => {
  const res = await client.get<RawValidResponse<OpportunitiesBudget>>(`/account/volunteering-summary`, {
    withCredentials: true
  })

  return res.data.data
}
