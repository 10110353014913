import { useCallback, useContext } from 'react'

import { useAuth } from '../useAuth/useAuth'

import { AnalyticsContext } from '@percent/workplace-giving/context/analytics/analytics/AnalyticsContext/AnalyticsContext'
import { redactFromLocationProperties } from '@percent/utility'
import { getAccountFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { AnalyticsEvent } from '@percent/workplace-giving/context/analytics/analytics/AnalyticsContext/AnalyticsContext.types'

export const useIdentify = () => {
  const analytics = useContext(AnalyticsContext)

  const identify = useCallback(
    ({ accountId, traits }: { accountId: string; traits?: Record<string, any> }) => {
      analytics.identify({
        accountId,
        traits
      })
    },
    [analytics.identify]
  )

  return {
    identify
  }
}

export const useAnalytics = () => {
  const analytics = useContext(AnalyticsContext)
  const { state } = useAuth()
  const account = getAccountFromAuthState(state)

  if (analytics === undefined) {
    throw new Error('useAnalyticsService must be used within a AnalyticsContextController')
  }

  const page = useCallback(
    ({ name, category, properties }: { category: string; name: string; properties?: Record<string, any> }) => {
      analytics.page({
        category,
        name,
        properties: { ...properties, url: redactFromLocationProperties('token').url },
        accountId: account?.id
      })
    },
    [analytics, account?.id]
  )

  const track = useCallback(
    ({ event, properties }: { event: AnalyticsEvent; properties?: Record<string, any> }) => {
      analytics.track({
        event,
        properties: {
          ...properties,
          url: redactFromLocationProperties('token').url
        },
        accountId: account?.id
      })
    },
    [analytics, account?.id]
  )

  const identify = useCallback(
    ({ accountId, traits }: { accountId: string; traits?: Record<string, any> }) => {
      analytics.identify({
        accountId,
        traits
      })
    },
    [analytics]
  )

  return {
    track,
    page,
    identify,
    loaded: analytics.loaded
  }
}
