import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@percent/lemonade'
import { Organisation } from '@percent/workplace-giving/api/search/searchOrganisations/searchOrganisations.types'
import { Styles } from './OpportunityCard.styles'

export function OpportunityCardFooter({ name, logo }: Pick<Readonly<Organisation>, 'name' | 'logo'>) {
  const { t } = useTranslation()

  return (
    <Box sx={Styles.ContentFooter} data-testid="orgInfoFooter">
      <Typography>{t('workplace_giving.volunteering.youWillBeHelping')}</Typography>
      <Box sx={Styles.FooterOrgInfo}>
        <Typography>{name}</Typography>
        {logo && <Avatar image={logo} size="small" alt="" />}
      </Box>
    </Box>
  )
}
