/* eslint-disable jsx-a11y/accessible-emoji */
import { Box, Text, Title } from '@mantine/core'
import { clsx } from '@mantine/styles'
import pick from 'lodash/pick'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@percent/workplace-giving/common/hooks'
import { getAccountFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import classes from './WelcomeHeader.module.css'

export type WelcomeHeaderProps = Readonly<{
  scrolled?: boolean
}>

export function WelcomeHeader({ scrolled = false }: WelcomeHeaderProps): JSX.Element {
  const { t } = useTranslation()
  const authState = useAuth()
  const account = getAccountFromAuthState(authState.state)!
  const { cssVariables } = useColorTheme()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isFirstLogin = useMemo(() => !account?.preferredName, [])

  return (
    <Box
      className={clsx(classes.Wrapper, { [classes.WrapperScrolled]: scrolled })}
      style={pick(cssVariables, '--primary')}
    >
      <Box className={classes.ContentWrapper}>
        <Text className={classes.Icon} style={pick(cssVariables, '--warningAlt100')}>
          <Box className={classes.Wave} component="span" role="img" aria-label="Waving hand">
            👋
          </Box>
        </Text>
        <Box className={clsx(classes.GreetingContainer, { [classes.GreetingContainerScrolled]: scrolled })}>
          <Title order={2} className={classes.GreetingTitle} style={pick(cssVariables, '--black')}>
            {isFirstLogin
              ? t('workplace_giving.new_home.greeting.title.firstTime', {
                  name: account?.fullName || undefined,
                  interpolation: { escapeValue: false }
                })
              : t('workplace_giving.new_home.greeting.title.regular', {
                  name: account?.preferredName || undefined,
                  interpolation: { escapeValue: false }
                })}
          </Title>
          <Text className={classes.GreetingSubtitle}>{t('workplace_giving.new_home.greeting.subtitle')}</Text>
        </Box>
      </Box>
    </Box>
  )
}
