import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { OpportunityCard as OpportunityCardLayout } from '@percent/domain/volunteering'
import type { BadgeVariant } from '@percent/lemonade'
import { OpportunityType } from '@percent/workplace-giving/api/opportunity/dto'
import type { SearchOpportunitiesResponseRow } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import type { LocaleKey } from '@percent/workplace-giving/i18n/config'
import { OpportunityCardContent } from './OpportunityCardContent'
import { OpportunityCardFooter } from './OpportunityCardFooter'

export type OpportunityCardProps = Readonly<{
  opportunity: SearchOpportunitiesResponseRow
  className?: string
  track?: VoidFunction
}>

export function OpportunityCard({ opportunity, className, track }: OpportunityCardProps): JSX.Element {
  const { t } = useTranslation()

  const skillsList = useMemo(
    () =>
      opportunity.skills
        ? opportunity.skills?.map(skill => ({
            tag: t(`workplace_giving.volunteering.skills.${skill.id}` as LocaleKey, {
              defaultValue: skill.name
            }),
            badgeVariant: 'default' as BadgeVariant
          }))
        : [],
    [opportunity.skills, t]
  )

  return (
    <OpportunityCardLayout
      className={className}
      to={`/volunteer/opportunities/${opportunity.id}`}
      tags={[
        {
          tag:
            opportunity.type === OpportunityType.Project
              ? t('workplace_giving.volunteering.project')
              : t('workplace_giving.volunteering.event'),
          badgeVariant: 'informative'
        },
        ...skillsList
      ]}
      image={opportunity.image}
      title={opportunity.name}
      content={<OpportunityCardContent {...opportunity} />}
      footer={opportunity.organisation ? <OpportunityCardFooter {...opportunity.organisation} /> : null}
      track={track}
    />
  )
}
