import { AppRoute } from '../../routing/AppRoute.enum'

export const getPageTrackingName = (location: typeof AppRoute[keyof typeof AppRoute]) => {
  switch (location) {
    case AppRoute.HOME:
      return 'Home'
    case AppRoute.VOLUNTEER:
      return 'Volunteer'
    case AppRoute.MY_IMPACT:
      return 'My impact'
    case AppRoute.MY_OPPORTUNITY:
      return 'My Opportunities'
    case AppRoute.MY_FUNDRAISER:
      return 'My Fundraisers'
    case AppRoute.CREATE_OPPORTUNITY:
      return 'Create Opportunity'
    case AppRoute.EDIT_OPPORTUNITY:
      return 'Edit Opportunity'
    case AppRoute.LOG_VOLUNTEERING_ACTIVITY:
      return 'Log Volunteering Time'
    case AppRoute.FUNDRAISER_WIZARD:
      return 'Create Fundraiser'
    case AppRoute.EDIT_FUNDRAISER:
      return 'Edit Fundraiser'
    case AppRoute.OPPORTUNITY_DETAILS:
      return 'Opportunity Details'
    case AppRoute.FUNDRAISER:
      return 'Fundraiser Details'
    case AppRoute.NEW_HOME:
      return 'New Home'
    default:
      return undefined
  }
}
