import { type To, useNavigate } from 'react-router-dom-v6'
import { useTranslation } from 'react-i18next'

import { ButtonText } from '@percent/lemonade'

export type SeeAllBtnProps = Readonly<{
  href: To
}>

export function SeeAllBtn({ href }: SeeAllBtnProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return <ButtonText onPress={() => navigate(href)}>{t('workplace_giving.common.seeAll')}</ButtonText>
}
