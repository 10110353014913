import { Navigate, useLocation } from 'react-router-dom-v6'

import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'

type AppRouteKey = keyof typeof AppRoute
type AppRouteValue = typeof AppRoute[AppRouteKey]

function pathToRegex(path: string): RegExp {
  const regexPath = path.replace(/:[^\s/]+/g, '([^/]+)')

  return new RegExp(`^${regexPath}$`)
}

function checkIfPathnameMatchesAppRoute(route: string): AppRouteKey | null {
  const trimmedRoute = route.replace('/home/', '/')

  const { NEW_HOME: _, ...props } = AppRoute

  const entries = Object.entries(props) as [AppRouteKey, AppRouteValue][]
  for (const [key, path] of entries) {
    const regex = pathToRegex(path)

    if (regex.test(trimmedRoute)) {
      return key
    }
  }

  return null
}

export function HandleNotFoundRedirection() {
  const { pathname } = useLocation()

  if (checkIfPathnameMatchesAppRoute(pathname)) {
    return <Navigate to={pathname.replace('/home/', '')} />
  }

  return <Navigate to={AppRoute.HOME} />
}
