export const supportedLocoLocales = [
  'da-DK',
  'de-CH',
  'de-DE',
  'en',
  'es-ES',
  'es-419',
  'fr-CA',
  'fr-FR',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'sv-SE',
  'ms-MY',
  'th-TH',
  'id-ID',
  'vi-VN',
  'hu-HU',
  'cs-CZ',
  'ro-RO',
  'fil-PH',
  'tr-TR'
]

// TODO: add translated ones to the list in order to release
export const supportedAppLocales = ['en']
